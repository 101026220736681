






import Vue from 'vue';
import Scaffold from '@/components/Scaffold.vue';
import HeroHeader from '@/components/HeroHeader.vue';
import TourList from '@/components/TourList.vue';
import { mapGetters } from 'vuex';
export default Vue.extend({
  components: { Scaffold, HeroHeader, TourList },
  data() {
    return {
      showAccountMenu: false,
      buttons: [
        // {
        //   label: 'Neue Tour',
        //   onClick: () => {
        //     this.$router.push({ name: 'New Tour' });
        //   },
        // },
      ],
    };
  },
  mounted() {
    this.$store.dispatch('tour/bindPastToursRef');
  },
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
      tours: 'tour/getParticipatedTours',
    }),
    participatedTours() {
      return this.tours(this.user.data.uid);
    },
  },
  methods: {},
});
