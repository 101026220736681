





































import Vue from 'vue';
export default Vue.extend({
  name: 'HeroHeader',
  data() {
    return {};
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    buttons: {
      type: Array,
      required: true,
    },
  },
  computed: {},
  methods: {},
});
