

































































































































































import Vue from 'vue';
import { formatShortDate } from '@/utils/date_time';
import { mapGetters } from 'vuex';
export default Vue.extend({
  name: 'TourList',
  data() {
    return {};
  },
  props: {
    tours: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
    }),
  },
  methods: {
    formatLocation(tour: any) {
      const location = tour.location || tour.startPoint;
      return location.description || location.locality;
    },
    formatDate(input: any): string {
      return formatShortDate(input, true);
    },
    membersCount(tour: any): number {
      if (tour.members) {
        return Object.keys(tour.members).length || 1;
      }
      return 1;
    },
  },
});
